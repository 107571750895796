import React from 'react';
import Start from './components/Start';

function App() {
  return (
    <div>
      <Start />
    </div>
  )
}

export default App;
